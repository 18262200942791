<template>
    <div>
        <div class="holder container-fluid">
            <div class="top-header">
                <h4>Landlord & Properties</h4>
                <h6>Efficiently manage assigned properties, landlords, track statuses, assign FM/IO, and access reports.</h6>
            </div>

            <div class="top-btn">
                <button class="btn" :class="[activeTab == 1 ? 'active-tab-btn' : '']" @click="activeTab = 1">Properties</button>
                <button class="btn" :class="[activeTab == 2 ? 'active-tab-btn' : '']"  @click="activeTab = 2">Landlords</button>
            </div>

            <div>
                <div class="mt-5 form-group col-md-6" style="padding-left: 0; padding-right: 0; gap: 10px;">
                    <input class="form-control input-field search" type="text" name="search" id="search" placeholder="Search...">
                </div>

                <div class="table-responsive tableList" v-if="activeTab == 1">
                    <!-- <div class="m-auto text-center mt-4 d-none" v-if="loadingDashboard"><Loader/></div> -->
                    <!-- <table class="table" v-if="!loadingDashboard && assingedFMArray.length != 0"> -->
                        <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">No.</th>
                                <th scope="">Id</th>
                                <th scope="col">Property</th>
                                <th scope="col">Landlord</th>
                                <th scope="col">Facility Manager</th>
                                <th scope="col">Insp. fficer</th>
                                <th scope="col">Vendors</th>
                                <th scope="col">Units</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr @click="moveToPropertyDetails(id)">
                                <td>1</td>
                                <td>RR1026345434</td>
                                <td>44, Gana Street, Maitama Abuja</td>
                                <td>Jenny Jacksone</td>
                                <td>Ronald Richard Vesp</td>
                                <td>Ronald Richard Vesp</td>
                                <td>Olivia Musty-Clien</td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>RR1026345434</td>
                                <td>44, Gana Street, Maitama Abuja</td>
                                <td>Jenny Jacksone</td>
                                <td>Ronald Richard Vesp</td>
                                <td>Ronald Richard Vesp</td>
                                <td>Olivia Musty-Clien</td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>RR1026345434</td>
                                <td>44, Gana Street, Maitama Abuja</td>
                                <td>Jenny Jacksone</td>
                                <td>Ronald Richard Vesp</td>
                                <td>Ronald Richard Vesp</td>
                                <td>Olivia Musty-Clien</td>
                                <td>5</td>
                            </tr>
                            <!-- <tr v-for="(item, index) in assingedFMArray"
                                :key="index"
                                :value="item.id">
                                <td>{{index + 1}}</td>
                                <td>{{item.user.firstname}} {{item.user.lastname}}</td>
                                <td>{{item.user.email}}</td>
                                <td>{{item.user.phone}}</td>
                                <td>{{ item.user.address }}</td>
                            </tr> -->
                           
                        </tbody>
                    </table>
                    <!-- <div v-if="assingedFMArray.length == 0 && !loadingDashboard">
                        <EmptyState :emptyData="emptyDataObject" />
                    </div> -->
                </div>

                <div class="table-responsive tableList" v-if="activeTab == 2">
                    <!-- <div class="m-auto text-center mt-4 d-none" v-if="loadingDashboard"><Loader/></div> -->
                    <!-- <table class="table" v-if="!loadingDashboard && assingedFMArray.length != 0"> -->
                        <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Property owned</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Olivia Musty-Clien</td>
                                <td>olivia@gmail.com</td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Olivia Musty-Clien</td>
                                <td>olivia@gmail.com</td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Olivia Musty-Clien</td>
                                <td>olivia@gmail.com</td>
                                <td>2</td>
                            </tr>
                            <!-- <tr v-for="(item, index) in assingedFMArray"
                                :key="index"
                                :value="item.id">
                                <td>{{index + 1}}</td>
                                <td>{{item.user.firstname}} {{item.user.lastname}}</td>
                                <td>{{item.user.email}}</td>
                                <td>{{item.user.phone}}</td>
                                <td>{{ item.user.address }}</td>
                            </tr> -->
                           
                        </tbody>
                    </table>
                    <!-- <div v-if="assingedFMArray.length == 0 && !loadingDashboard">
                        <EmptyState :emptyData="emptyDataObject" />
                    </div> -->
                </div>

                
            </div>
        </div>
    </div>
</template>



<script>

export default {
    name: "PropertyManagement",
    // props: {
    //     emptyData: {
    //         type: Object,
    //         required: true,
    //     }
    // },
    data() {
        return {
            loading: false,
            activeTab: 1,
        }
    },
    methods: {
        moveToPropertyDetails() {
            this.$router.push({path: `/user/partner/property-details`});
        }
    },
}
</script>


<style scoped lang="scss">

.holder {
    background: #ffffff;
    padding: 30px 15px;
}

.top-header {
    h4 {
        font-family: Lato;
        font-weight: 600;
        font-size: 24px;
        color: #2B3352;
    }
    h6 {
        font-family: Lato;
        font-weight: 400;
        font-size: 16px;
        color: #555C74;

    }
}
 
.top-btn {
    background: #FAFAFA;
    border: 1px solid #E9EAEB;
    border-radius: 10px;
    width: fit-content;
    padding: 4px;
    margin-top: 30px;
    button {
        font-family: Lato;
        font-weight: 600;
        font-size: 14px;
        color: #555C74;
        box-shadow: none;
        outline: 0;
        height: 44px;
    }
}
.active-tab-btn {
    font-family: Lato;
    font-weight: 600;
    font-size: 14px;
    background: #ffffff;
    border-radius: 6px;

}

.input-field {
    height: 48px;
    outline: 0;
    box-shadow: none;
}

.search {
   width: 70%; 
}

.tableList {
    // margin-top: 30px;
    margin-bottom: 20px;
    border: 2px solid #EAECF0;
    border-radius: 20px;
}


.table thead th {
    border-bottom: none;
    
}
.table td {
    border-top: 1px solid #EAECF0;
}
.table th {
    border-top: none;
}

thead > tr {
    background: #EAECF0;
}
thead th {
    white-space: nowrap;
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #667085
}

tbody td {
    padding: 18px .75rem;
    white-space: nowrap;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #667085
}
</style>