var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"holder container-fluid"},[_vm._m(0),_c('div',{staticClass:"top-btn"},[_c('button',{staticClass:"btn",class:[_vm.activeTab == 1 ? 'active-tab-btn' : ''],on:{"click":function($event){_vm.activeTab = 1}}},[_vm._v("Properties")]),_c('button',{staticClass:"btn",class:[_vm.activeTab == 2 ? 'active-tab-btn' : ''],on:{"click":function($event){_vm.activeTab = 2}}},[_vm._v("Landlords")])]),_c('div',[_vm._m(1),(_vm.activeTab == 1)?_c('div',{staticClass:"table-responsive tableList"},[_c('table',{staticClass:"table"},[_vm._m(2),_c('tbody',[_c('tr',{on:{"click":function($event){return _vm.moveToPropertyDetails(_vm.id)}}},[_c('td',[_vm._v("1")]),_c('td',[_vm._v("RR1026345434")]),_c('td',[_vm._v("44, Gana Street, Maitama Abuja")]),_c('td',[_vm._v("Jenny Jacksone")]),_c('td',[_vm._v("Ronald Richard Vesp")]),_c('td',[_vm._v("Ronald Richard Vesp")]),_c('td',[_vm._v("Olivia Musty-Clien")]),_c('td',[_vm._v("2")])]),_vm._m(3),_vm._m(4)])])]):_vm._e(),(_vm.activeTab == 2)?_c('div',{staticClass:"table-responsive tableList"},[_vm._m(5)]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-header"},[_c('h4',[_vm._v("Landlord & Properties")]),_c('h6',[_vm._v("Efficiently manage assigned properties, landlords, track statuses, assign FM/IO, and access reports.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5 form-group col-md-6",staticStyle:{"padding-left":"0","padding-right":"0","gap":"10px"}},[_c('input',{staticClass:"form-control input-field search",attrs:{"type":"text","name":"search","id":"search","placeholder":"Search..."}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("No.")]),_c('th',{attrs:{"scope":""}},[_vm._v("Id")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Property")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Landlord")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Facility Manager")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Insp. fficer")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Vendors")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Units")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("1")]),_c('td',[_vm._v("RR1026345434")]),_c('td',[_vm._v("44, Gana Street, Maitama Abuja")]),_c('td',[_vm._v("Jenny Jacksone")]),_c('td',[_vm._v("Ronald Richard Vesp")]),_c('td',[_vm._v("Ronald Richard Vesp")]),_c('td',[_vm._v("Olivia Musty-Clien")]),_c('td',[_vm._v("10")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("1")]),_c('td',[_vm._v("RR1026345434")]),_c('td',[_vm._v("44, Gana Street, Maitama Abuja")]),_c('td',[_vm._v("Jenny Jacksone")]),_c('td',[_vm._v("Ronald Richard Vesp")]),_c('td',[_vm._v("Ronald Richard Vesp")]),_c('td',[_vm._v("Olivia Musty-Clien")]),_c('td',[_vm._v("5")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("No.")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Name")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Email")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Property owned")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("1")]),_c('td',[_vm._v("Olivia Musty-Clien")]),_c('td',[_vm._v("olivia@gmail.com")]),_c('td',[_vm._v("2")])]),_c('tr',[_c('td',[_vm._v("2")]),_c('td',[_vm._v("Olivia Musty-Clien")]),_c('td',[_vm._v("olivia@gmail.com")]),_c('td',[_vm._v("2")])]),_c('tr',[_c('td',[_vm._v("3")]),_c('td',[_vm._v("Olivia Musty-Clien")]),_c('td',[_vm._v("olivia@gmail.com")]),_c('td',[_vm._v("2")])])])])
}]

export { render, staticRenderFns }